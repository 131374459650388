<template>
  <div class="business-info">
    <Header v-bind:type="type" />
    <Breadcrumb_business />
    <MainId />
    <div class="ll-body container" role="main">

      <table v-if="debug" class="dump">
        <tr>
          <td>errCnt:</td>
          <td>{{ errCnt }}</td>
        </tr>
      </table>

      <form v-on:submit.prevent>
        <section class="ll-section">
          <h1 class="form-title">Connect to Banker</h1>

          <h2 class="section-title">Tell Us About Your Business</h2>
          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img class="page-error-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div class="ll-row row2-container">
            <!-- Business Name -->
            <div class="ll-item">
              <div class="tf-label" id="business-label-legal-name"><label for="business-legal-name-input">Business Legal Name<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-textfield tf-fname" data-test="cb-business-legal-name" type="text" id="business-legal-name-input" aria-describedby="legal-name bus-legal-err" name="business-legal-name-input"
                v-model="businessLegalName" :class="missingBusinessLegalName ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateBusinessLegalName" required>
              <div id="legal-name" class="tf-note">
                If you're a sole proprietorship or independent contractor and do not have a business entity, such as an LLC, you can use your legal name.
              </div>
              <div id="bus-legal-err" class="ind-err-container">
                <div v-if="missingBusinessLegalName" class="form-error-msg" data-test="cb-business-legal-name-error">
                  <img class="form-error-icon"
                    srcset="../../../assets/images/exclaimation-lg.png 1x, ../../../assets/images/exclaimation-lg-2x.png 2x"
                    src="../../../assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter your business name</div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-spacer-30" />
          <label class="box-checkbox" for="alt-business-name" id="business-label-alt-busniess-name-checkbox">
            <input class="business-banker" type="checkbox" id="alt-business-name" name="alt-business-name"
              v-model="use_alt_business_name" @click="handleAltBusinessName()"
              data-test="business-alternate-name-indicator">
            <span> I do business under a different name</span>
          </label>
          <div class="ll-row row2-container">
            <div v-if="use_alt_business_name">
              <div class="tf-label" id="business-label-alt-business-name"><label for="business-alt-business-name-input">Doing business as Name</label></div>
              <input maxlength="512" class="ll-textfield" type="text" id="business-alt-business-name-input" aria-describedby="bus-alt-bus-name-err" name="business-alt-business-name-input" data-test="business-alternate-name"
                v-model="businessAltName" :class="missingBusinessAltName ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateBusinessAltName">
              <div id="bus-alt-bus-name-err" class="ind-err-container">
                <div v-if="missingBusinessAltName" class="form-error-msg" data-test="business-alternate-name-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter your business name</div>
                </div>
              </div>
            </div>
          </div>


          <div class="v-spacer-30" />
          <div class="v-spacer-30" />
          <!-- Nature of Business -->
          <div class="row2-container">
            <div class="ll-item ll-position">
              <div class="tf-label" id="business-label-nature"><label for="business-nature-input">Nature of Business<sup class="fhb-red">*</sup></label></div>
              <DropdownSearch 
                v-model="businessNature"
                :missingSelection="missingBusinessNature"
                id="business-nature-input-id"
                name="business-nature-input"
                :dropdownArray="businessOfNature"
                dataTest="ll-business-of-nature"
                @selected="validateBusinessNature"
                ariaDescribe="industry-tip business-nature-error"
              />
              <div id="industry-tip" class="tf-note-business">
                <p>We recommend searching for your industry by typing keywords in the field below. Alternatively, you can select using the dropdown. Example keywords might include construction, contractor, lawyer, stores, management and restaurants.</p>
                <p>Names with a "T" at the end indicate trilateral agreement (United States, Canada, and Mexico)</p>
              </div>
              <div id="business-nature-error" class="ind-err-container">
                <div v-if="missingBusinessNature" class="form-error-msg" data-test="cb-business-nature-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please select a business nature</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />

          <div class="v-spacer-30" />
          <div class="ll-row row2-container">
            <!-- Federal Tax ID -->
            <div>
              <div class="tf-label" id="business-label-tax-ID"><label for="business-tax-ID-input">
                {{ this.business_company_structure == 'sole' ? 'Social Security Number' : 'Federal Tax ID' }}</label><sup class="fhb-red">*</sup></div>
              <input maxlength="9" data-test="cb-federal-tax" class="ll-textfield" type="text" id="business-tax-ID-input" name="business-tax-ID-input" v-model="businessTaxID" aria-describedby="form-bus-tax-err"
                :class="missingBusinessTaxID || invalidBusinessTaxID? 'll-textfield-error' : 'll-textfield'" v-on:blur="validateBusinessTaxID" required>
              <div id="bus-tax-err" class="ind-err-container">
                <div v-if="missingBusinessTaxID || invalidBusinessTaxID" class="form-error-msg" data-test="cb-federal-tax-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">{{ this.business_company_structure == 'sole' ? 'Please Enter your SSN' : 'Please Enter your Federal Tax Id' }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Business Established -->

          <div class="v-spacer-30" />
          <div class="ll-row row2-container">
            <div class="ll-item">
              <div class="tf-label" id="business-label-established-date"><label for="business-established-date-input">Business Established Date</label><sup class="fhb-red">*</sup></div>
              <birthday-input class="ll-textfield tf-dob" id="business-established-date-input" aria-describedby="bus-est-date-err" name="business-established-date-input" v-model="businessDate"
                :class="missingDate || invalidDate ? 'll-textfield-error' : 'll-textfield'" v-on:blur="validateBusinessDate"
                data-test="cb-business-established-date" required />
              <div id="bus-est-date-err" class="ind-err-container">
                <div v-if="missingDate || invalidDate" class="form-error-msg" data-test="cb-business-established-date-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a valid date (MM/DD/YYYY)</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />


          <!-- Owned Business Time -->
          <div class="ll-row">
            <div class="ll-row">
              <div class="tf-label">How long have you owned the business?<sup class="fhb-red">*</sup></div>
            </div>
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label" id="business-label-years-owned"><label for="business-years-owned-input">Years</label><sup class="fhb-red">*</sup></div>
                <input maxlength="512" class="ll-textfield tf-addr-years" type="text" id="business-years-owned-input" name="business-years-owned-input" aria-describedby="years-owned-tip bus-yrs-owned-err" v-model="businessYearsOwned"
                  :class="missingYearsOwned || invalidYearsOwned ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateYearsOwned" data-test="cb-years-owned" required>
                <div id="years-owned-tip" class="tf-note">
                  Input 0 years if less than 1 year.
                </div>
                <div id="bus-yrs-owned-err" class="ind-err-container">
                  <div v-if="missingYearsOwned || invalidYearsOwned" class="form-error-msg" 
                    data-test="cb-years-owned-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter the number of years</div>
                  </div>
                </div>
              </div>

              <div class="ll-item">
                <div class="tf-label" id="business-label-months-owned"><label for="business-months-owned-input">Months</label><sup class="fhb-red">*</sup></div>
                <select class="ll-select select-addr-months" name="business-months-owned" id="business-months-owned-input" aria-describedby="mos-owned-err" v-model="businessMonthsOwned"
                  :class="missingMonthsOwned ? 'll-select-error' : 'll-select'" 
                  @change="validateMonthsOwned($event)" data-test="cb-months-owned" required>
                  <option value="">Select months</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                </select>
                <div id="mos-owned-err" class="ind-err-container">
                  <div v-if="missingMonthsOwned" class="form-error-msg" data-test="cb-months-owned-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please select months</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Business Annual gross revenue -->
          <div class="v-spacer-40" />
          <div class="ll-row">
            <div class="ll-row row4-container">
              <div class="ll-item">
                <div class="tf-label" id="business-label-gross-revenue"><label for="business-gross-revenue-input">Annual Gross Revenue<sup class="fhb-red">*</sup></label>
                </div>
                <div class="ll-prefix-wrap">
                  <money-input class="ll-textfield tf-nt-monthly-income ll-textfield-money" id="business-gross-revenue-input" aria-describedby="bus-gross-err" name="business-gross-revenue-input"
                    v-model="businessAnnualGrossRevenue"
                    :class="missingBusinessAnnualGrossRevenue || invalidBusinessAnnualGrossRevenue ? 'll-textfield-error' : 'll-textfield'"
                    v-on:blur="validateBusinessAnnualGrossRevenue" data-test="cb-business-annual-gross-revenue" required />
                </div>
                <div id="bus-gross-err" class="ind-err-container">
                  <div v-if="missingBusinessAnnualGrossRevenue || invalidBusinessAnnualGrossRevenue"
                    class="form-error-msg" data-test="cb-business-annual-gross-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter your business annual gross revenue</div>
                  </div>
                </div>
              </div>

              <!--Number of employees -->
              <div class="ll-item">
                <div class="tf-label" id="business-label-employees"><label for="business-number-employess-input">Number of Employees<sup class="fhb-red">*</sup></label>
                </div>
                <input maxlength="512" class="ll-textfield tf-addr-years" type="text" id="business-number-employess-input" aria-describedby="bus-emp-err" name="business-number-employess-input" v-model="businessNumEmployees"
                  :class="missingBusinessNumEmployees || invalidBusinessNumEmployees ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateBusinessNumEmployees" data-test="cb-num-employees" required>
                <div id="bus-emp-err" class="ind-err-container">
                  <div v-if="missingBusinessNumEmployees || invalidBusinessNumEmployees" class="form-error-msg"
                    data-test="cb-num-employees-error">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter the number of employees</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="v-spacer-40" />

          <!-- Country of Formation -->
          <div class="row2-container">
            <div class="ll-item ll-position">
              <div class="tf-label" id="business-label-country-formation"><label for="business-country-formation-input">Business Country of Formation<sup
                  class="fhb-red">*</sup></label></div>
              <select class="ll-select" name="company_position" id="business-country-formation-input" aria-describedby="bus-form-country-err" v-model="businessCountryFormation"
                :class="missingBusinessCountryFormation ? 'll-select-error' : 'll-select'"
                @change="validateBusinessCountryFormation($event)" data-test="cb-country-formation" required>
                <option value="">Select Your Country of Formation</option>
                <option value="United States">United States</option>
                <option value="Japan">Japan</option>
                <option value="Philippines">Philippines</option>
                <option v-for="country in countries" :value="country" v-bind:key="country">{{ country }}</option>
              </select>
              <div id="bus-form-country-err" class="ind-err-container">
                <div v-if="missingBusinessCountryFormation" class="form-error-msg" data-test="cb-country-formation-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please select a Country</div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-30" />
          <div class="v-spacer-30" />

          <!-- Delinquent Tax -->
          <RadioInputs :inputValues="inputArr" 
            id="business-nature" name="business-nature-name" 
            v-model="businessDelinquentTax" 
            legendText="Are any tax payments delinquent?"
            :missingSelection="missingDelinquentTaxPayment"
          />

          <div class="v-spacer-20" />


          <!-- Lawsuit -->
          <RadioInputs 
            :inputValues="inputArr" id="business-lawsuit" 
            name="business-lawsuit-name" v-model="businessLawsuit" 
            legendText="Is the company or any owner currently involved in any lawsuit or pending litigation?"
            :missingSelection="missingLawsuit"
          />
          <div class="v-spacer-10" />

          <!-- lawsuit or litigation explanation -->
          <div class="ll-item" v-if="businessLawsuit == 'yes'" id="business-label-lawsuit-reason">
            <div class="tf-label"><label for="business-lawsuit-input">If yes, please explain</label><sup class="fhb-red">*</sup></div>
            <input maxlength="512" class="ll-textfield tf-fname" type="text" name="business-lawsuit-input" id="business-lawsuit-input" aria-describedby="bus-lawsuit-err" v-model="businessLawsuitReason"
              :class="missingBusinessLawsuitReason ? 'll-textfield-error' : 'll-textfield'"
              v-on:blur="validateBusinessLawsuitReason()" data-test="cb-lawsuit-reason" required>
            <div id="bus-lawsuit-err">
              <div v-if="missingBusinessLawsuitReason" class="form-error-msg">
                <img class="form-error-icon"
                  srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                  src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                <div role="alert" class="form-error-txt">Please explain the lawsuit or litigation.</div>
              </div>
            </div>
          </div>

          <div class="v-spacer-60" />

          <!-- Business address -->
          <div class="ll-row row1-container">

            <div class="ll-item ll-address">
              <div class="tf-label" id="business-label-addr"><label for="business-addr-input">Business Street Address (No P.O. Box allowed)<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-textfield tf-address" type="text" name="business-addr-input" id="business-addr-input" aria-describedby="bus-addr1-err" v-model="businessAddress"
                :class="missingBusinessAddress || invalidBusinessAddress ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateBusinessAddress" data-test="cb-business-addr" required>
              <div id="bus-addr1-err" class="ind-err-container">
                <div v-if="missingBusinessAddress || invalidBusinessAddress" class="form-error-msg" data-test="cb-business-addr-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter an address</div>
                </div>
              </div>
            </div>

            <div class="ll-item">
              <div class="tf-label"><label for="business-apt-input">Apt, Unit, etc.</label></div>
              <input maxlength="512" class="ll-textfield tf-apt" name="business-apt-input" id="business-apt-input" type="text" v-model="businessAddress2" data-test="cb-business-addr2">
            </div>
          </div>

          <div class="v-spacer-30" />


          <div class="ll-row row3-container">

            <div class="ll-item ll-city">
              <div class="tf-label" id="business-label-city"><label for="business-city-input">City<sup class="fhb-red">*</sup></label></div>
              <input maxlength="512" class="ll-textfield tf-city" type="text" id="business-city-input" name="business-city-input" aria-describedby="bus-addrcity-err" v-model="businessCity"
                :class="missingBusinessCity ? 'll-textfield-error' : 'll-textfield'" v-on:blur="validateBusinessCity"
                data-test="cb-business-city" required>
              <div id="bus-addrcity-err" class="ind-err-container">
                <div v-if="missingBusinessCity" class="form-error-msg" data-test="cb-business-city-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a city</div>
                </div>
              </div>
            </div>

            <div class="ll-item ll-state">
              <div class="tf-label" id="business-label-state"><label for ="business-state-input">State or Territory<sup class="fhb-red">*</sup></label></div>
              <select class="ll-select select-state" name="us_state" id="business-state-input" v-model="businessState" aria-describedby="bus-addrstate-err"
                :class="missingBusinessState ? 'll-select-error' : 'll-select'" 
                @change="validateBusinessState($event)" data-test="cb-business-state" required>
                <option value="">Select a state</option>
                <option v-for="us_state in us_states" :value="us_state.value" v-bind:key="us_state.key">{{ us_state.key
                }}</option>
              </select>
              <div id="bus-addrstate-err" class="ind-err-container">
                <div v-if="missingBusinessState" class="form-error-msg" data-test="cb-business-state-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please select a state</div>
                </div>
              </div>
            </div>

            <div class="ll-item ll-zipcode">
              <div class="tf-label" id="business-label-zipcode"><label for="business-zipcode-input">ZIP Code<sup class="fhb-red">*</sup></label></div>
              <input maxlength="12" class="ll-textfield tf-zipcode" type="text" id="business-zipcode-input" name="business-zipcode-input" aria-describedby="bus-addrzip-err" v-model="businessZipcode"
                :class="missingBusinessZipcode || invalidBusinessZipcode ? 'll-textfield-error' : 'll-textfield'"
                v-on:blur="validateBusinessZipcode" data-test="cb-business-zipcode" required>
              <div id="bus-addrzip-err" class="ind-err-container">
                <div v-if="missingBusinessZipcode || invalidBusinessZipcode" class="form-error-msg"
                  data-test="cb-business-zipcode-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a valid ZIP code</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Business alt mailing address -->
          <div class="v-spacer-10" />
          <div class="ll-row">

            <div class="ll-item">
              <input class="cb-alt-mailing-addr" type="checkbox" id="alt-mailing-addr-cb" name="alt-mailing-addr-cb"
                v-model="use_alt_mailing_addr" @click="handleAltMailingAddrCB()"
                data-test="cb-business-mailing-add-indicator">
              <label for="alt-mailing-addr-cb">Business Mailing Address is the same </label>
            </div>

            <div class="v-spacer-30" />

            <div v-if="!use_alt_mailing_addr">


              <div class="ll-row row1-container">
                <div class="ll-item ll-address">
                  <div class="tf-label" id="business-label-mailing-addr"><label for="business-mailing-addr-input">Mailing Address (P.O. Box is okay)</label><sup class="fhb-red">*</sup></div>
                  <input maxlength="512" class="ll-textfield tf-address" type="text" name="business-mailing-addr-input" id="business-mailing-addr-input" aria-describedby="bus-mail-err" v-model="businessMailingAddress"
                    :class="missingBusinessMailingAddress ? 'll-textfield-error' : 'll-textfield'"
                    v-on:blur="validateBusinessMailingAddress" data-test="cb-business-mailing-addr" required>
                  <div id="bus-mail-err" class="ind-err-container">
                    <div v-if="missingBusinessMailingAddress" class="form-error-msg"
                      data-test="cb-business-mailing-addr-error">
                      <img class="form-error-icon"
                        srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                        src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                      <div role="alert" class="form-error-txt">Please enter an address</div>
                    </div>
                  </div>
                </div>

                <div class="ll-item">
                  <div class="tf-label"><label for="business-mailing-unit-input">Apt, Unit, etc.</label></div>
                  <input maxlength="512" class="ll-textfield tf-apt" type="text" id="business-mailing-city-input" name="unit" v-model="businessMailingAddress2"
                    data-test="cb-business-mailing-addr2">
                </div>
              </div>

              <div class="v-spacer-30" />


              <div class="ll-row row3-container">
                <div class="ll-item ll-city">
                  <div class="tf-label" id="business-label-mailing-city"><label for="business-mailing-city-input">City<sup class="fhb-red">*</sup></label></div>
                  <input maxlength="512" class="ll-textfield tf-city" type="text" id="business-mailing-city-input" name="city" aria-describedby="bus-mailcity-err" v-model="businessMailingCity"
                    :class="missingBusinessMailingCity ? 'll-textfield-error' : 'll-textfield'"
                    v-on:blur="validateBusinessMailingCity" data-test="cb-business-mailing-city" required>
                  <div id="bus-mailcity-err" class="ind-err-container">
                    <div v-if="missingBusinessMailingCity" class="form-error-msg"
                      data-test="cb-business-mailing-city-error">
                      <img class="form-error-icon"
                        srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                        src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                      <div role="alert" class="form-error-txt">Please enter a city</div>
                    </div>
                  </div>
                </div>

                <div class="ll-item ll-state">
                  <div class="tf-label" id="business-label-mailing-state"><label for="business-mailing-state-input">State or Territory<sup class="fhb-red">*</sup></label>
                  </div>
                  <select class="ll-select select-state" name="us_state" id="business-mailing-state-input" aria-describedby="bus-mailstate-err" v-model="businessMailingState"
                    :class="missingBusinessMailingState ? 'll-select-error' : 'll-select'"
                    @change="validateBusinessMailingState($event)" data-test="cb-business-mailing-state" required>
                    <option value="">Select a state</option>
                    <option v-for="us_state in us_states" :value="us_state.value" v-bind:key="us_state.key">{{
                        us_state.key
                    }}</option>
                  </select>
                  <div id="bus-mailstate-err" class="ind-err-container">
                    <div v-if="missingBusinessMailingState" class="form-error-msg" data-test="cb-business-mailing-error">
                      <img class="form-error-icon"
                        srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                        src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                      <div role="alert" class="form-error-txt">Please select a state</div>
                    </div>
                  </div>
                </div>

                <div class="ll-item ll-zipcode">
                  <div class="tf-label" id="business-label-mailing-zipcode"><label for="business-mailing-zip-input">ZIP Code<sup class="fhb-red">*</sup></label></div>
                  <input maxlength="12" class="ll-textfield tf-zipcode" type="text" name="business-mailing-zip-input" id="business-mailing-zip-input" aria-describedby="bus-mailzip-err" v-model="businessMailingZipcode"
                    :class="missingBusinessMailingZipcode || invalidBusinessMailingZipcode ? 'll-textfield-error' : 'll-textfield'"
                    v-on:blur="validateBusinessMailingZipcode" data-test="cb-business-mailing-zipcode" required>
                  <div id="bus-mailzip-err" class="ind-err-container">
                    <div v-if="missingBusinessMailingZipcode || invalidBusinessMailingZipcode" class="form-error-msg"
                      data-test="cb-business-mailing-error">
                      <img class="form-error-icon"
                        srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                        src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                      <div role="alert" class="form-error-txt">Please enter a valid ZIP code</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="v-spacer-40" />

          <!-- Business Phone Number -->
          <div class="ll-row row2-container">
            <div class="ll-item ll-position">
              <div class="tf-label" id="business-label-phone"><label for="business-phone-input">Business Phone Number<sup class="fhb-red">*</sup></label></div>
              <div class="ll-prefix-wrap">
                <phone-input class="tf-phone ll-textfield" id="business-phone-input" name="business-phone-input" aria-describedby="bus-phone-tip bus-phone-err" v-model="businessPhoneNumber"
                  :class="missingBusinessPhoneNumber || invalidBusinessPhoneNumber ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateBusinessPhoneNumber" data-test="cb-business-phone" required />
              </div>
              <div id="bus-phone-tip" class="tf-note">
                Please provide a U.S. phone number.
              </div>
              <div id="bus-phone-err" class="ind-err-container">
                <div v-if="missingBusinessPhoneNumber || invalidBusinessPhoneNumber" class="form-error-msg"
                  data-test="cb-business-phone-error">
                  <img class="form-error-icon"
                    srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                    src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                  <div role="alert" class="form-error-txt">Please enter a valid phone number</div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="v-spacer-40" />

          <!-- Errors from BE -->
          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img class="form-error-icon sys-err-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : {{ item.error_value }}
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button type="button" class="back-btn" @click="handleBack()" data-test="nav-back">
              <img class="back-icon" srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png" alt="" />
              <span class="sr-only">Back</span>
            </button>
            <button id="ll-continue-btn" type="button" class="continue-btn" @click="handleContinue()"
              data-test="nav-continue">
              <span class="continue-text">Continue</span>
            </button>
          </div>
          <div class="v-spacer-40" />

        </section>
      </form>
    </div>
    <Footer />
  </div>

</template>


<script>
import Api from '@/app/ll-commercial-api'
import Header from '@/components/Header'
import DropdownSearch from '@/components/CustomInputs/DropdownSearch.vue'
import Validator from '@/app/validation'
import MainId from '@/components/MainId'
import Constants from '@/app/business_constants'
import Footer from '@/components/Footer'
import Breadcrumb_business from '@/views/business/connect_banker/Breadcrumb_business.vue'
import jQuery from 'jquery'
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/app/utils'
import PhoneInput from '@/components/CustomInputs/PhoneInput.vue'
import BirthdayInput from '../../../components/CustomInputs/BirthdayInput.vue'
import MoneyInput from '@/components/CustomInputs/MoneyInput'
import RadioInputs from '../../../components/CustomInputs/RadioInputs.vue';

export default {
  name: 'Business Information',
  components: {
    Header,
    MainId,
    Footer,
    Breadcrumb_business,
    PhoneInput,
    BirthdayInput,
    MoneyInput,
    RadioInputs,
    DropdownSearch
  },
  data() {
    return {
      systemErrorMsg: '',
      use_alt_business_name: false,
      use_alt_mailing_addr: false,
      us_states: Constants.USStates,
      countries: Constants.Countries,

      businessOfNature: Constants.NatureOfBusiness6,

      errCnt: 0,

      missingDelinquentTaxPayment: false,
      // delinquentTaxIndicator: false,
      missingLawsuit: false,
      // lawsuitIndicator: false,

      missingBusinessLegalName: false,

      missingBusinessAltName: false,

      missingBusinessNature: false,
      missingBusinessTaxID: false,
      invalidBusinessTaxID: false,
      missingDate: false,
      invalidDate: false,

      missingYearsOwned: false,
      invalidYearsOwned: false,
      missingMonthsOwned: false,

      missingBusinessAnnualGrossRevenue: false,
      invalidBusinessAnnualGrossRevenue: false,

      missingBusinessNumEmployees: false,
      invalidBusinessNumEmployees: false,

      missingBusinessCountryFormation: false,

      missingBusinessLawsuitReason: false,

      missingBusinessAddress: false,
      invalidBusinessAddress: false,

      missingBusinessCity: false,

      missingBusinessState: false,

      missingBusinessZipcode: false,
      invalidBusinessZipcode: false,

      missingBusinessMailingAddress: false,

      missingBusinessMailingCity: false,

      missingBusinessMailingState: false,

      missingBusinessMailingZipcode: false,
      invalidBusinessMailingZipcode: false,

      missingBusinessPhoneNumber: false,
      invalidBusinessPhoneNumber: false,
      
      inputArr: Constants.radioInputValues

    }
  },
  created() {
    this.setCurrentPage(2);
  },
  computed: {
    ...mapGetters("connectToBanker",
      ['debug', 'currentPage', 'type', 'application_id', 'business_delinquent_tax', 'business_lawsuit', 'jwt_token', 'business_alt_name_indicator', 'business_alt_mailing_address_indicator', 'business_company_structure']
    ),
    businessLegalName: {
      get() { return this.$store.state.connectToBanker.business_legal_name },
      set(value) { this.$store.commit('connectToBanker/setBusinessLegalName', value) }
    },
    businessAltNameIndicator: {
      get() { return this.$store.state.connectToBanker.business_alt_name_indicator },
      set(value) { this.$store.commit('connectToBanker/setBusinessAltNameIndicator', value) }
    },
    businessAltName: {
      get() { return this.$store.state.connectToBanker.business_alt_name },
      set(value) { this.$store.commit('connectToBanker/setBusinessAltName', value) }
    },
    businessNature: {
      get() { return this.$store.state.connectToBanker.business_nature },
      set(value) { this.$store.commit('connectToBanker/setBusinessNature', value) }
    },
    businessTaxID: {
      get() { return this.$store.state.connectToBanker.business_tax_ID },
      set(value) { this.$store.commit('connectToBanker/setBusinessTaxID', value) }
    },
    businessDate: {
      get() { return this.$store.state.connectToBanker.business_date },
      set(value) { this.$store.commit('connectToBanker/setBusinessDate', value) }
    },
    businessYearsOwned: {
      get() { return this.$store.state.connectToBanker.business_years_owned },
      set(value) { this.$store.commit('connectToBanker/setBusinessYearsOwned', value) }
    },
    businessMonthsOwned: {
      get() { return this.$store.state.connectToBanker.business_months_owned },
      set(value) { this.$store.commit('connectToBanker/setBusinessMonthsOwned', value) }
    },
    businessAnnualGrossRevenue: {
      get() { return this.$store.state.connectToBanker.business_annual_gross_revenue },
      set(value) { this.$store.commit('connectToBanker/setBusinessAnnualGrossRevenue', value) }
    },
    businessNumEmployees: {
      get() { return this.$store.state.connectToBanker.business_number_of_employees },
      set(value) { this.$store.commit('connectToBanker/setBusinessNumberOfEmployees', value) }
    },
    businessCountryFormation: {
      get() { return this.$store.state.connectToBanker.business_country_formation },
      set(value) { this.$store.commit('connectToBanker/setBusinessCountryFormation', value) }
    },
    businessLawsuitReason: {
      get() { return this.$store.state.connectToBanker.business_lawsuit_reason },
      set(value) { this.$store.commit('connectToBanker/setBusinessLawsuitReason', value) }
    },
    businessAddress: {
      get() { return this.$store.state.connectToBanker.business_address },
      set(value) { this.$store.commit('connectToBanker/setBusinessAddress', value) }
    },
    businessAddress2: {
      get() { return this.$store.state.connectToBanker.business_address2 },
      set(value) { this.$store.commit('connectToBanker/setBusinessAddress2', value) }
    },
    businessCity: {
      get() { return this.$store.state.connectToBanker.business_city },
      set(value) { this.$store.commit('connectToBanker/setBusinessCity', value) }
    },
    businessState: {
      get() { return this.$store.state.connectToBanker.business_state },
      set(value) { this.$store.commit('connectToBanker/setBusinessState', value) }
    },
    businessZipcode: {
      get() { return this.$store.state.connectToBanker.business_zipcode },
      set(value) { this.$store.commit('connectToBanker/setBusinessZipcode', value) }
    },
    appCurrPage: {
      get() { return this.$store.state.connectToBanker.currentPage },
      set(value) { this.$store.commit('connectToBanker/setCurrentPage', value) }
    },
    businessDelinquentTax: {
      get() { return this.$store.state.connectToBanker.business_delinquent_tax },
      set(value) { this.$store.commit('connectToBanker/setDelinquentTaxPayment', value) }
    },
    businessDelinquentTaxIndicator: {
      get() { return this.$store.state.connectToBanker.business_delinquent_tax_indicator },
      set(value) { this.$store.commit('connectToBanker/setDelinquentTaxPaymentIndicator', value) }
    },
    businessLawsuit: {
      get() { return this.$store.state.connectToBanker.business_lawsuit },
      set(value) { this.$store.commit('connectToBanker/setLawsuit', value) }
    },
    businessLawsuitIndicator: {
      get() { return this.$store.state.connectToBanker.business_lawsuit_indicator },
      set(value) { this.$store.commit('connectToBanker/setLawsuitIndicator', value) }
    },
    businessMailingSameIndicator: {
      get() { return this.$store.state.connectToBanker.business_alt_mailing_address_indicator },
      set(value) { this.$store.commit('connectToBanker/setBusinessAltMailingAddressIndicator', value) }
    },
    businessMailingAddress: {
      get() { return this.$store.state.connectToBanker.business_mailing_address },
      set(value) { this.$store.commit('connectToBanker/setBusinessMailingAddress', value) }
    },
    businessMailingAddress2: {
      get() { return this.$store.state.connectToBanker.business_mailing_address2 },
      set(value) { this.$store.commit('connectToBanker/setBusinessMailingAddress2', value) }
    },
    businessMailingCity: {
      get() { return this.$store.state.connectToBanker.business_mailing_city },
      set(value) { this.$store.commit('connectToBanker/setBusinessMailingCity', value) }
    },
    businessMailingState: {
      get() { return this.$store.state.connectToBanker.business_mailing_state },
      set(value) { this.$store.commit('connectToBanker/setBusinessMailingState', value) }
    },
    businessMailingZipcode: {
      get() { return this.$store.state.connectToBanker.business_mailing_zipcode },
      set(value) { this.$store.commit('connectToBanker/setBusinessMailingZipcode', value) }
    },
    businessPhoneNumber: {
      get() { return this.$store.state.connectToBanker.business_phone_number },
      set(value) { this.$store.commit('connectToBanker/setBusinessPhoneNumber', value) }
    },
    documents: {
      get() { return this.$store.state.connectToBanker.document_upload_setup },
      set(value) { this.$store.commit('connectToBanker/setDocumentUpload', value) }
    },
    additionalDocuments: {
      get() { return this.$store.state.connectToBanker.additional_document_upload_setup },
      set(value) { this.$store.commit('connectToBanker/setAdditionalDocumentUpload', value) }
    },

  },
  mounted() {
    this.setType('commercial-loan')
    jQuery("html, body").animate({ scrollTop: 0 });
    this.use_alt_business_name = this.business_alt_name_indicator ? true : false
    this.use_alt_mailing_addr = this.business_alt_mailing_address_indicator ? true : false

  },
  methods: {
    ...mapActions("connectToBanker",
      ['setDelinquentTaxPayment', 'setLawsuit', 'setAppID', 'setBusinessLegalName', 'setBusinessAltNameIndicator', 'setBusinessAltName', 'setBusinessNature', 'setBusinessTaxID', 'setBusinessDate', 'setBusinessYearsOwned', 'setBusinessMonthsOwned', 'setBusinessAnnualGrossRevenue', 'setBusinessNumberOfEmployees', 'setCurrentPage', 'setBusinessCountryFormation',
        'setBusinessLawsuitReason', 'setBusinessAddress', 'setBusinessAddress2', 'setBusinessCity', 'setBusinessState', 'setBusinessZipcode', 'setBusinessAltMailingAddressIndicator', 'setBusinessMailingAddress', 'setBusinessMailingAddress2', 'setBusinessMailingCity', 'setBusinessMailingState', 'setBusinessMailingZipcode', 'setBusinessPhoneNumber', 'setJWTToken', 'setType', 'setValidatedNavPage',
        'setLawsuitIndicator', 'setDelinquentTaxPaymentIndicator', 'setDocumentUpload', 'setAdditionalDocumentUpload']
    ),
    async putBankerPage(payload) {
      this.systemErrorMsg = '';
      this.systemErrorArray = [];

      const resp = await Api.putBankerPage(this.application_id, payload, this.jwt_token);
      console.log('--- response ---');
      console.log(resp);

      if (resp && resp.data) {
        let FileNames = [];
        let FileNames2 = [];

        if (resp.data.status == "success") {

          for (let i = 0; i < 3; i++) {
            FileNames.push(resp.data.response.documents[i])
          }
          this.setDocumentUpload(FileNames)
          for (let i = 0; i < 3; i++) {
            FileNames2.push(resp.data.response.additional_documents[i])
          }
          this.setAdditionalDocumentUpload(FileNames2)


          // update breadcrumb state
          this.setValidatedNavPage({ name: 'business', isValid: 1 });

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400));

          this.$router.push({ name: 'Document Upload' })

          Utils.removeProcessingMask('#ll-continue-btn')
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message;
          }
          if (resp.data.response == null) {
            Utils.removeProcessingMask('#ll-continue-btn')
          }
          if (resp.data.response.application_errors && (resp.data.response.application_errors.length > 0)) {
            for (const x of resp.data.response.applicant_errors) {
              for (const y of x.errors) {
                this.systemErrorArray.push(y)
              }
            }
          }
          Utils.removeProcessingMask('#ll-continue-btn')
        }
      }
    },
    handleBack() {
      this.$router.push({ name: 'Personal Information' })
    },
    handleContinue() {
      Utils.addProcessingMask('#ll-continue-btn')


      this.validatePage();

      this.$nextTick(() => {
        this.errCnt = jQuery('.form-error-txt').length;

        if (!this.errCnt) {
          const payload =
          {
            "page": this.currentPage,
            "business_legal_name": this.businessLegalName,
            "business_different_name_indicator": this.businessAltNameIndicator,
            "business_different_name": this.businessAltName,
            "business_nature": this.businessNature.value,
            "federal_tax_id": this.businessTaxID,
            "business_established_date": this.businessDate,
            "business_owned_years": parseInt(this.businessYearsOwned),
            "business_owned_months": parseInt(this.businessMonthsOwned),
            "annual_gross_revenue": parseFloat(this.businessAnnualGrossRevenue.replaceAll(',', '').replaceAll('$', '')),
            "number_of_employees": parseInt(this.businessNumEmployees),
            "business_country_of_formation": this.businessCountryFormation,
            "tax_payment_delinquent": this.businessDelinquentTaxIndicator,
            "litigation_indicator": this.businessLawsuitIndicator,
            "litigation_explanation": this.businessLawsuitReason,
            "business_street_address": this.businessAddress,
            "business_street_address_2": this.businessAddress2,
            "business_city": this.businessCity,
            "business_state": this.businessState,
            "business_zip": this.businessZipcode,
            "business_mailing_same": this.businessMailingSameIndicator,
            "business_mailing_street_address": this.businessMailingAddress,
            "business_mailing_street_address_2": this.businessMailingAddress2,
            "business_mailing_city": this.businessMailingCity,
            "business_mailing_state": this.businessMailingState,
            "business_mailing_zip": this.businessMailingZipcode,
            "business_phone_number": this.businessPhoneNumber ? Utils.formatPhoneNumber(this.businessPhoneNumber) : "",

          }

          this.putBankerPage(payload);
        } else {
          this.setValidatedNavPage({ name: 'business', isValid: 0 });

          if (this.errCnt > 1) {
            jQuery("html, body").animate({ scrollTop: jQuery("#commercial-page-errors").offset().top - 20 });
          } else {
            let offset = 0;

            if (this.missingBusinessLegalName) offset = jQuery("#business-label-legal-name").offset().top
            if (this.businessLawsuitIndicator) {
              if (this.missingBusinessLawsuitReason) offset = jQuery("#business-label-lawsuit-reason").offset().top
            }
            if (this.missingBusinessAltName) offset = jQuery("#business-label-alt-business-name").offset().top
            if (this.missingBusinessNature) offset = jQuery("#business-label-nature").offset().top
            if (this.missingBusinessTaxID || this.invalidBusinessTaxID) offset = jQuery("#business-label-tax-ID").offset().top
            if (this.missingDate || this.invalidDate) offset = jQuery("#business-label-established-date").offset().top
            if (this.missingYearsOwned || this.invalidYearsOwned) offset = jQuery("#business-label-years-owned").offset().top
            if (this.missingMonthsOwned) offset = jQuery("#business-label-months-owned").offset().top
            if (this.missingBusinessAnnualGrossRevenue || this.invalidBusinessAnnualGrossRevenue) offset = jQuery("#business-label-gross-revenue").offset().top
            if (this.missingBusinessNumEmployees || this.invalidBusinessNumEmployees) offset = jQuery("#business-label-employees").offset().top
            if (this.missingBusinessCountryFormation) offset = jQuery("#business-label-country-formation").offset().top
            if (this.missingBusinessAddress || this.invalidBusinessAddress) offset = jQuery("#business-label-addr").offset().top
            if (this.missingBusinessCity) offset = jQuery("#business-label-city").offset().top
            if (this.missingBusinessState) offset = jQuery("#business-label-state").offset().top
            if (this.missingBusinessZipcode || this.invalidBusinessZipcode) offset = jQuery("#business-label-zipcode").offset().top
            if (!this.businessMailingSameIndicator) {
              if (this.missingBusinessMailingAddress) offset = jQuery("#business-label-mailing-addr").offset().top
              if (this.missingBusinessMailingCity) offset = jQuery("#business-label-mailing-city").offset().top
              if (this.missingBusinessMailingState) offset = jQuery("#business-label-mailing-state").offset().top
              if (this.missingBusinessMailingZipcode || this.invalidBusinessMailingZipcode) offset = jQuery("#business-label-mailing-zipcode").offset().top
            }

            if (this.missingBusinessPhoneNumber || this.invalidBusinessPhoneNumber) offset = jQuery("#business-label-phone").offset().top


            jQuery("html, body").animate({ scrollTop: offset });
          }
          // remove processing
          Utils.removeProcessingMask('#ll-continue-btn')
        }

      })

    },
    validatePage() {
      this.validateDelinquentTaxPayment();
      this.validateLawsuit();
      this.validateBusinessLegalName();
      if (this.use_alt_business_name) {
        this.validateBusinessAltName();
      }
      this.validateBusinessNature(); 
      this.validateBusinessTaxID();
      this.validateBusinessDate();
      this.validateYearsOwned();
      this.validateMonthsOwned();
      this.validateBusinessAnnualGrossRevenue();
      this.validateBusinessNumEmployees();
      this.validateBusinessCountryFormation();
      if (this.businessLawsuit) {
        this.validateBusinessLawsuitReason();
      }
      this.validateBusinessAddress();
      this.validateBusinessCity();
      this.validateBusinessState();
      this.validateBusinessZipcode();
      if (!this.use_alt_mailing_addr) {
        this.validateBusinessMailingAddress();
        this.validateBusinessMailingCity();
        this.validateBusinessMailingState();
        this.validateBusinessMailingZipcode();
      }
      this.validateBusinessPhoneNumber();

    },
    validateBusinessPhoneNumber() {
      this.missingBusinessPhoneNumber = this.businessPhoneNumber ? false : true;
      if (!this.missingBusinessPhoneNumber) {
        this.invalidBusinessPhoneNumber = Validator.isValidPhoneNumber(this.businessPhoneNumber) ? false : true;
      }
    },
    handleAltBusinessName() {
      let cb = jQuery('#alt-business-name')
      if (cb.prop("checked") == true) {
        this.use_alt_business_name = true;
        this.$store.commit('connectToBanker/setBusinessAltNameIndicator', true)
      }
      else if (cb.prop("checked") == false) {
        this.use_alt_business_name = false;
        this.$store.commit('connectToBanker/setBusinessAltNameIndicator', false)
      }
    },
    validateLawsuit() {
      if (this.businessLawsuit == 'yes') {
        this.businessLawsuitIndicator = true
      } else if (this.businessLawsuit == 'no') {
        this.businessLawsuitIndicator = false
      } 
      this.missingLawsuit = this.businessLawsuit ? false : true
      
    },
    validateDelinquentTaxPayment() {
      if (this.businessDelinquentTax == 'yes') {
        this.businessDelinquentTaxIndicator = true
      }
      else if (this.businessDelinquentTax == 'no') {
        this.businessDelinquentTaxIndicator = false
      } else {
        this.missingDelinquentTaxPayment = this.businessDelinquentTaxIndicator ? false : true;
      }
    },
    // toggleDelinquentTaxPayment(val) {
    //   this.businessDelinquentTax = val
    //   if (val == "yes") {
    //     this.businessDelinquentTaxIndicator = true
    //     // this.delinquentTaxIndicator = true,
    //       // this.$store.commit('connectToBanker/setDelinquentTaxPaymentIndicator', true)
    //   } else if (val == "no") {
    //     this.businessDelinquentTaxIndicator = false
    //     // this.delinquentTaxIndicator = false,
    //     //   this.$store.commit('connectToBanker/setDelinquentTaxPaymentIndicator', false)
    //   }
    //   this.validateDelinquentTaxPayment()
    // },
    // toggleLawsuit(val) {
    //   this.businessLawsuit = val
    //   if (val == "yes") {
    //     this.lawsuitIndicator = true,
    //     this.businessLawsuitIndicator = true
    //       // this.$store.commit('connectToBanker/setLawsuitIndicator', true)
    //   } else if (val == "no") {
    //     this.lawsuitIndicator = false,
    //     this.businessLawsuitIndicator = false
    //       // this.$store.commit('connectToBanker/setLawsuitIndicator', false)
    //   }
    //   this.validateLawsuit()
    // },
    handleAltMailingAddrCB() {
      let cb = jQuery('#alt-mailing-addr-cb')
      if (cb.prop("checked") == true) {
        this.use_alt_mailing_addr = true;
        this.$store.commit('connectToBanker/setBusinessAltMailingAddressIndicator', true)
      }
      else if (cb.prop("checked") == false) {
        this.use_alt_mailing_addr = false;
        this.$store.commit('connectToBanker/setBusinessAltMailingAddressIndicator', false)
      }
    },
    validateBusinessLegalName() {
      this.businessLegalName = this.businessLegalName.trim()
      this.missingBusinessLegalName = this.businessLegalName ? false : true;
    },
    validateBusinessAltName() {
      this.businessAltName = this.businessAltName.trim()
      this.missingBusinessAltName = this.businessAltName ? false : true;
    },
    validateBusinessNature() {
      console.log(this.businessNature)
      if (this.businessNature) {
        this.businessNature.key = this.businessNature.key.trim()
      }
      this.missingBusinessNature = this.businessNature ? false : true;
    },
    validateBusinessTaxID() {
      this.businessTaxID = this.businessTaxID.trim()
      this.missingBusinessTaxID = this.businessTaxID ? false : true;
      if (!this.missingBusinessTaxID){
        this.invalidBusinessTaxID = this.businessTaxID.length != 9
      }
    },
    validateBusinessDate() {
      this.missingDate = !this.businessDate || this.businessDate == ""
      if (!this.missingDate) {
        this.invalidDate = false
        if (!Validator.isValidDate(this.businessDate)) {
          this.invalidDate = true
          return
        }
        try {
          const date = Date.parse(this.businessDate)
          if (date > Date.now()) {
            this.invalidDate = true
            return
          }
        } catch (err) {
          console.log(err)
          this.invalidDate = true
          return
        }
      }
    },
    validateYearsOwned() {
      if (this.businessYearsOwned) {
        this.businessYearsOwned = this.businessYearsOwned.trim()
      }
      if (!this.businessYearsOwned || this.businessYearsOwned == "") {
        this.missingYearsOwned = true
      } else {
        this.missingYearsOwned = false
      }
      if (!this.missingYearsOwned) {
        this.invalidYearsOwned = Validator.isValidNumber(this.businessYearsOwned) ? false : true;
      }

    },
    validateMonthsOwned() {
      this.missingMonthsOwned = this.businessMonthsOwned ? false : true;
    },
    validateBusinessAnnualGrossRevenue() {
      if (!this.businessAnnualGrossRevenue || this.businessAnnualGrossRevenue == "") {
        this.missingBusinessAnnualGrossRevenue = true
      } else {
        this.missingBusinessAnnualGrossRevenue = false
      }
    },
    validateBusinessNumEmployees() {
      this.businessNumEmployees = this.businessNumEmployees.trim()
      this.missingBusinessNumEmployees = (!this.businessNumEmployees || this.businessNumEmployees == "") 
        
      if (!this.missingBusinessNumEmployees) {
        this.invalidBusinessNumEmployees = Validator.isValidNumber(this.businessNumEmployees) ? false : true;
      }
    },
    validateBusinessCountryFormation() {
      this.missingBusinessCountryFormation = this.businessCountryFormation ? false : true;
    },
    validateBusinessLawsuitReason() {
      this.businessLawsuitReason = this.businessLawsuitReason.trim()
      this.missingBusinessLawsuitReason = this.businessLawsuitReason ? false : true;
    },
    validateBusinessAddress() {
      if (this.businessAddress) {
        this.businessAddress = this.businessAddress.trim()
      }
      this.missingBusinessAddress = this.businessAddress ? false : true;
      if (!this.missingBusinessAddress) this.invalidBusinessAddress = Validator.containsPOBox(this.businessAddress)
    },
    validateBusinessCity() {
      this.businessCity = this.businessCity.trim()
      this.missingBusinessCity = this.businessCity ? false : true;
    },
    validateBusinessState() {
      this.missingBusinessState = this.businessState ? false : true;
    },
    validateBusinessZipcode() {
      this.businessZipcode = this.businessZipcode.trim()
      this.missingBusinessZipcode = this.businessZipcode ? false : true;
      if (!this.missingBusinessZipcode) {
        this.invalidBusinessZipcode = Validator.isValidZipcode(this.businessZipcode) ? false : true;
      }
    },
    validateBusinessMailingAddress() {
      this.businessMailingAddress = this.businessMailingAddress.trim()
      this.missingBusinessMailingAddress = this.businessMailingAddress ? false : true;
    },
    validateBusinessMailingCity() {
      this.businessMailingCity = this.businessMailingCity.trim()
      this.missingBusinessMailingCity = this.businessMailingCity ? false : true;
    },
    validateBusinessMailingState() {
      this.missingBusinessMailingState = this.businessMailingState ? false : true;
    },
    validateBusinessMailingZipcode() {
      this.businessMailingZipcode = this.businessMailingZipcode.trim()
      this.missingBusinessMailingZipcode = this.businessMailingZipcode ? false : true;
      if (!this.missingBusinessMailingZipcode) {
        this.invalidBusinessMailingZipcode = Validator.isValidZipcode(this.businessMailingZipcode) ? false : true;
      }
    },

    gotoFHB() {
      // form validation
      window.location.replace('https://fhb.com');
    },
  }
}
</script>


<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

th,
td {
  text-align: left;
  padding-right: 15px;
  padding: 10px;
}

.loan-or-line {
  outline: 1px dotted $orange;
}

.ll-section {
  text-align: left;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}


.toggle-btn-container .icon {
  flex-basis: 32px;
  text-align: center;
}

.toggle-btn-container .toggle-btn-txt {
  flex: 1;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-container-error {
  border: 1px dotted $red;
  padding: 10px;
}

.toggle-btn-container {
  align-items: stretch;
  column-gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2px;
  row-gap: 1em;
}

.toggle-btn-container>* {
  column-gap: 0.25em;
  display: flex;

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
}

.toggle-btn-txt-bold {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}

.toggle-btn-up,
.toggle-btn-down {
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 100px;
  padding-right: 100px;
}

.toggle-btn-up {
  background: $white;
  border: 1px solid $grayMid;
  padding-left: 100px;
  padding-right: 100px;
  box-shadow: 0px 3px 0px rgba(176, 176, 176, 0.5);

  &:hover,
  &:focus,
  &:active {
    background: rgba(254, 214, 123, 0.2);
    border: 1px solid $grayDark;
    box-shadow: 0px 3px 0px rgb(80 80 80 / 60%);
  }
}

.toggle-btn-down {
  background: rgba(254, 214, 123, 0.2);
  border: 1px solid $grayDark;
  box-shadow: inset 2px 4px 0px rgb(114 79 0 / 15%);
}

.toggle-btn-up:hover {
  cursor: pointer;
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.row3-container {
  @include media-breakpoint-up(md) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }

  >div+div {
    @include media-breakpoint-down(md) {
      margin-top: 1.5em;
    }
  }
}

.row4-container {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {}
}

.contact-container {
  background-color: $grayBg;
}

.ll-item {
  position: relative;
}

.ll-address {
  @include media-breakpoint-up(lg) {
    .tf-address {
      min-width: 500px;
      max-width: unset;
    }
  }

  @include media-breakpoint-down(lg) {
    flex: 1;
  }
}

.tf-apt {
  min-width: 180px;
}

.ll-city {
  @include media-breakpoint-up(xl) {
    .tf-city {
      min-width: 380px;
    }
  }

  @include media-breakpoint-down(xl) {
    flex: 1;
    max-width: unset;
  }
}

.ll-zipcode {
  @include media-breakpoint-up(lg) {
    .tf-zipcode {
      width: 210px;
    }
  }
}

.ll-state {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {
    .select-state {
      width: 160px;
    }
  }
}

.style {
  box-sizing: border-box; 
  border-radius: 3px;
  --vs-border-color: #B0B0B0;
  --vs-line-height: 2.5;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.ll-select-error-business-nature {
  background: $white;
  border: 1px solid $red;
}

.tf-note-business {
  margin-top: 4px;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
}

/*.box-checkbox {
  border: 1px solid $gray;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 1em;
  padding: 1em;
}*/

.box-checkbox {
  display: inline-block;
  margin-bottom: 0.5em;
}

#business-label-nature {
  width: 100%;
}
.input-line {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin: 20px 0;
}
</style>